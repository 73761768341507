import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';

export default createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: {
      main: '#ba68c8'
    }
  },
  typography: {
    fontFamily: 'Lato',
    useNextVariants: true
  }
});
