export const items = [
  {
    Title: 'Activities',
    Items: [
      {
        xs: 4,
        sm: 3,
        image: 'relax.svg',
        text: 'Relaxation'
      },
      {
        xs: 4,
        sm: 3,
        image: 'beach.svg',
        text: 'Beach'
      },
      {
        xs: 4,
        sm: 3,
        image: 'chatting.svg',
        text: 'Interesting chats!'
      },
      {
        xs: 4,
        sm: 3,
        image: 'hiking.svg',
        text: 'Hike Around the Lake'
      },
      {
        xs: 4,
        sm: 3,
        image: 'swimming.svg',
        text: 'Swimming'
      },
      {
        xs: 4,
        sm: 3,
        image: 'kayak.svg',
        text: 'Kayaking Canoeing'
      },
      {
        xs: 4,
        sm: 3,
        image: 'campfire.svg',
        text: 'Campfire Sing-a-long'
      }
    ]
  },
  {
    Title: 'Amenities',
    Items: [
      {
        xs: 6,
        image: 'cabin.svg',
        text: 'Cabins, Shared or Private'
      },
      {
        xs: 6,
        image: 'meals.svg',
        text: 'Meals Cooked for You'
      }
    ]
  },
  {
    Title: 'Schedule',
    Items: [
      {
        title: 'Registration, Cabin Setup',
        start: '2020-06-05T18:00:00',
        end: '2020-06-05T19:30:00'
      },
      {
        title: 'Ingathering',
        start: '2020-06-05T19:30:00',
        end: '2020-06-05T20:30:00'
      },
      {
        title: 'Campfire, Snack Potluck',
        start: '2020-06-05T20:30:00',
        end: '2020-06-05T21:30:00'
      },
      {
        title: 'Quiet',
        start: '2020-06-05T22:00:00',
        end: '2020-06-06T00:00:00'
      },
      {
        title: 'Breakfast',
        start: '2020-06-06T08:30:00',
        end: '2020-06-06T09:30:00'
      },
      {
        title: 'School of Magic',
        start: '2020-06-06T10:00:00',
        end: '2020-06-06T12:00:00'
      },
      {
        title: 'Choir',
        start: '2020-06-06T10:00:00',
        end: '2020-06-06T11:30:00'
      },
      {
        title: 'Lunch',
        start: '2020-06-06T12:00:00',
        end: '2020-06-06T13:30:00'
      },
      {
        title: 'Canoeing & Kayaking',
        start: '2020-06-06T13:00:00',
        end: '2020-06-06T17:00:00'
      },
      {
        title: 'School of Magic',
        start: '2020-06-06T13:30:00',
        end: '2020-06-06T15:30:00'
      },
      {
        title: 'Choir',
        start: '2020-06-06T13:30:00',
        end: '2020-06-06T15:30:00'
      },
      {
        title: 'Dinner',
        start: '2020-06-06T17:30:00',
        end: '2020-06-06T19:00:00'
      },
      {
        title: 'Spin the Wheel',
        start: '2020-06-06T19:30:00',
        end: '2020-06-06T20:30:00'
      },
      {
        title: 'Campfire & Sing-a-long',
        start: '2020-06-06T20:30:00',
        end: '2020-06-06T22:30:00'
      },
      {
        title: 'Group Games (Lodge)',
        start: '2020-06-06T20:30:00',
        end: '2020-06-06T22:30:00'
      },
      {
        title: 'Quiet',
        start: '2020-06-06T23:00:00',
        end: '2020-06-07T00:00:00'
      },
      {
        title: 'Breakfast',
        start: '2020-06-07T08:30:00',
        end: '2020-06-07T09:30:00'
      },
      {
        title: 'Choir',
        start: '2020-06-07T09:30:00',
        end: '2020-06-07T10:30:00'
      },
      {
        title: 'Intergenerational Service',
        start: '2020-06-07T10:30:00',
        end: '2020-06-07T11:30:00'
      },
      {
        title: 'Lunch',
        start: '2020-06-07T12:00:00',
        end: '2020-06-07T13:30:00'
      },
      {
        title: 'Canoeing and Kayaking',
        start: '2020-06-07T12:30:00',
        end: '2020-06-07T15:30:00'
      },
      {
        title: 'Final Challenges',
        start: '2020-06-07T12:30:00',
        end: '2020-06-07T15:30:00'
      },
      {
        title: 'Camp Closed',
        start: '2020-06-07T16:00:00',
        end: '2020-06-04T00:00:00'
      }
    ]
  },
  {
    Title: 'Packing',
    Items: [
      {
        SubTitle: 'Recommended Items',
        Items: [
          {
            text:
              'Flashlight (paths leading to the cabins from the main lodge are not lit at night)'
          },
          {
            text: 'Clothes for cold, rain, heat, extra shoes, umbrella'
          },
          {
            text: 'Towel, face cloth, soap, personal care items'
          },
          {
            text:
              'Sleeping bag, pillow, (foam pad for extra comfort if you sleep on your side)'
          },
          {
            text: 'Earplugs (for light sleepers)'
          }
        ]
      },
      {
        SubTitle: 'Optional Items',
        Items: [
          {
            text: 'Travel mug'
          },
          {
            text: 'Camera'
          },
          {
            text: 'Sunscreen, sun glasses, insect repellent'
          },
          {
            text:
              'Swimming suit and towel, life jackets, sand toys for younger campers'
          },
          {
            text: 'Cards, board games, puzzles, books'
          },
          {
            text: 'Musical instruments, songs'
          },
          {
            text:
              "Ideas, talent, skits, costumes for Sat evening's 'Spin the Wheel'"
          },
          {
            text: 'Stories/grace for meals, spoken or sung'
          }
        ]
      }
    ]
  }
];
