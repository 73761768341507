import React, { PureComponent } from 'react'; //eslint-disable-line
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import RegistrationIcon from '@material-ui/icons/GroupAddOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Hidden from '@material-ui/core/Hidden';
import MailIcon from '@material-ui/icons/MailOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Contact from './Contact';
import styled from '@emotion/styled';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const RootDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
`;

export default class Header extends PureComponent {
  state = {
    contactOpen: false
  };

  handleClickContactOpen = () => {
    this.setState({ contactOpen: true });
  };

  handleClickContactClose = () => {
    this.setState({ contactOpen: false });
  };

  justifyContent = () =>
    this.props.nohome
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: space-between;
        `;

  ToolBarStyle = props => css`
    display: flex;
    @media (min-width: 600px) {
      ${this.justifyContent()}
    }
    @media (max-width: 599px) {
      justify-content: center;
    }
  `;

  render() {
    const { background, notitle } = this.props;
    return (
      <RootDiv>
        <AppBar
          position='static'
          style={{ background: background, boxShadow: 'none' }}
        >
          <Toolbar css={this.ToolBarStyle}>
            {!notitle && (
              <Hidden xsDown>
                <div>
                  <Link to='/' style={{ textDecoration: 'none' }}>
                    <Typography
                      style={{ fontFamily: 'Princess Sofia', color: 'white' }}
                      variant='h6'
                    >
                      Camp Sasamat
                    </Typography>
                  </Link>
                </div>
              </Hidden>
            )}
            <div>
              <Hidden smUp>
                <Tooltip title='Home' aria-label='Home'>
                  <Button component={Link} to='/' color='inherit'>
                    <HomeIcon />
                  </Button>
                </Tooltip>
              </Hidden>

              <Hidden xsDown>
                <Tooltip title='Register' aria-label='Register'>
                  <Button
                    variant='outlined'
                    component={Link}
                    to='/register'
                    color='inherit'
                    style={{ marginLeft: '20px', marginRight: '20px' }}
                  >
                    <RegistrationIcon />
                  </Button>
                </Tooltip>
              </Hidden>
              <Hidden smUp>
                <Button component={Link} to='/register' color='inherit'>
                  <RegistrationIcon />
                </Button>
              </Hidden>

              <Tooltip title='Info' aria-label='Info'>
                <Button component={Link} to='/info' color='inherit'>
                  <InfoIcon />
                </Button>
              </Tooltip>
              {/*
              <Button component={Link} to='/jobs' color='inherit'>
                Jobs
              </Button>
              */}
              <Tooltip title='Contact Us' aria-label='Contact Us'>
                <Button onClick={this.handleClickContactOpen} color='inherit'>
                  <MailIcon />
                </Button>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Contact
          open={this.state.contactOpen}
          onClose={this.handleClickContactClose}
        />
        {this.props.children}
      </RootDiv>
    );
  }
}
