import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PaperDiv } from './Div';
import { GridPaper } from './Paper';

export const ShowListGrid = items =>
  items.map(l => (
    <Grid item key={l.image} xs={l.xs} sm={l.sm || l.xs}>
      <GridPaper elevation={1}>
        {l.image && (
          <PaperDiv>
            <img height='60' width='60' alt='' src={'./images/' + l.image} />
          </PaperDiv>
        )}
        <Typography>{l.text}</Typography>
      </GridPaper>
    </Grid>
  ));

export const ShowList = items =>
  items.map(l => (
    <li key={l.text}>
      <Typography>{l.text}</Typography>
    </li>
  ));
