import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HeroDiv from './HeroDiv';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';

const GridContainer = props => (
  <Grid
    container
    direction='row'
    justify='center'
    alignItems='center'
    spacing={24}
  >
    <Grid item>{props.children}</Grid>
  </Grid>
);

const MainTitle = props => (
  <Typography
    variant={props.variant}
    gutterBottom
    style={{
      fontFamily: 'Princess Sofia',
      textAlign: 'center',
      color: 'white'
    }}
  >
    {props.children}
  </Typography>
);

const TagLine = props => (
  <Typography
    variant={props.variant}
    gutterBottom
    style={{
      fontFamily: 'Montserrat',
      textAlign: 'center',
      color: 'white'
    }}
  >
    {props.children}
  </Typography>
);

export default class Home extends Component {
  render() {
    return (
      <HeroDiv>
        <Header background='transparent' notitle nohome />
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          spacing={24}
          height='100%'
          style={{ flexGrow: '1' }}
        >
          <Grid item>
            <GridContainer>
              <MainTitle variant='h3'>School of</MainTitle>
            </GridContainer>
            <GridContainer>
              <MainTitle variant='h3'>Witchcraft and</MainTitle>
            </GridContainer>
            <GridContainer>
              <MainTitle variant='h3'>Wizardry</MainTitle>
            </GridContainer>
            <GridContainer>
              <TagLine variant='h4'>June 5 - 7, 2020</TagLine>
            </GridContainer>
            <GridContainer>
              <TagLine variant='h4'>Family Camp @ Lake Sasamat</TagLine>
            </GridContainer>
            <Grid container direction='row' justify='center' spacing={24}>
              <Grid item>
                <Button
                  size='large'
                  variant='contained'
                  color='primary'
                  component={Link}
                  to='/register'
                >
                  Register
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    color: 'white',
                    border: '1px solid white'
                  }}
                  size='large'
                  variant='outlined'
                  color='inherit'
                  component={Link}
                  to='/info'
                >
                  More Info
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HeroDiv>
    );
  }
}
