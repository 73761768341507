import styled from '@emotion/styled';

const HeroDiv = styled('div')`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/images/home/Kids_ygqmk7_c_scale, w_1280.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: 190px) {
    .bg {
      background-image: url(/images/home/Kids_ygqmk7_c_scale, w_190.jpg);
    }
  }
  @media (max-width: 695px) {
    .bg {
      background-image: url(/images/home/Kids_ygqmk7_c_scale, w_695.jpg);
    }
  }
  @media (max-width: 1039px) {
    .bg {
      background-image: url(/images/home/Kids_ygqmk7_c_scale, w_1039.jpg);
    }
  }
`;

export default HeroDiv;
