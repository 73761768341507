import React from 'react';
//import Tab from '@material-ui/core/Tab';
//import MenuBar from '../../components/MenuBar';
import { PageTitle } from '../../components/Div';
//import * as Constants from './Constants';

const HeaderContent = props => (
  <>
    <PageTitle title='CAMP INFO' />
    {/*<MenuBar value={props.value} handleChange={props.handleChange}>
      {Constants.items
        .filter(item => item.Title !== 'Packing - Optional')
        .map(item => (
          <Tab key={item.Title} label={item.Title} />
        ))}
        </MenuBar>*/}
  </>
);

export default HeaderContent;
