import React, { useState } from 'react';
import Header from '../../components/Header';
import HeaderContent from './HeaderContent';
import Content from './Content';

const Info = props => {
  const [value, setValue] = useState(0);
  return (
    <>
      <Header>
        <HeaderContent value={value} handleChange={setValue} />
      </Header>
      <Content />
    </>
  );
};

export default Info;
