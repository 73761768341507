import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from '@emotion/styled';
import { SizeMe } from 'react-sizeme';

export const PaperDiv = styled('div')`
  width: 60;
  height: 60;
  margin: 0 auto;
  padding-bottom: 5px;
`;

export const ItemDiv = styled('div')`
  float: none;
`;

export const PageDiv = styled('div')`
  display: flex;
  justify-content: flex-start;
  max-width: 800px;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 840px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const FormDiv = styled('div')``;

export const PageTitle = props => (
  <div style={{ paddingTop: '20px', textAlign: 'center' }}>
    <Typography variant='h4'>
      <strong>{props.title}</strong>
    </Typography>
    <SizeMe>{({ size }) => <div>{/*size.width*/}</div>}</SizeMe>
  </div>
);

export const SectionDiv = styled('div')`
  justify-content: center;
`;

export const SectionHeader = props => (
  <Grid item xs={12} style={{ marginTop: props.marginTop || '40px' }}>
    <Grid container justify='center'>
      <Grid item>
        <Typography variant='h5'>{props.title}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

export const TabContainer = props => (
  <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  </div>
);
