import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppContainer from './containers/App';
import theme from './theme';

export default class App extends Component {
  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <AppContainer />
        </MuiThemeProvider>
      </Router>
    );
  }
}
