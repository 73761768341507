export default {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://kcscroptx9.execute-api.us-west-2.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-west-2',
    IDENTITY_POOL_ID: 'us-west-2:72d24bf8-adbc-4162-8848-02069575ea73'
  }
};
