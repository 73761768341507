import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import SnackBar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Terms from './Terms';
import { API } from 'aws-amplify';

class Contact extends PureComponent {
  state = {
    email: '',
    message: '',
    validEmail: true,
    confirmationSnackbarOpen: false,
    consented: false
  };

  validateEmail = email => {
    // prettier-ignore
    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line
    this.setState({ email: email });
    return regex.test(email)
      ? this.setState({ validEmail: true })
      : this.setState({ validEmail: false });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleMessageChange = message => {
    this.setState({ message: message });
  };

  handleConsent = event => {
    this.setState({ consented: event.target.checked });
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      await this.sendMessage({
        email: this.state.email,
        message: this.state.message
      });
      //this.props.history.push("/");
    } catch (e) {
      alert(e);
    }
    //console.log(this.state.email + ' ' + this.state.message);
    this.props.onClose();
    this.setState({ confirmationSnackbarOpen: true });
  };

  sendMessage(messagePayload) {
    return API.post('contactform', '/contactform', {
      body: messagePayload
    });
  }

  render() {
    const {
      email,
      message,
      validEmail,
      confirmationSnackbarOpen,
      consented
    } = this.state;
    const formFilled = email && message && validEmail && consented;
    return (
      <div>
        <form>
          <Dialog
            fullScreen={this.props.fullScreen}
            modal='true'
            open={this.props.open}
            onClose={this.props.onClose}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>CAMP QUESTIONS?</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ paddingBottom: '20px' }}>
                Send a message and we&apos;ll get back to you right away!
              </DialogContentText>

              <TextField
                autoFocus
                required
                id='email'
                label='Email'
                type='email'
                fullWidth
                style={{ paddingBottom: '10px' }}
                onChange={event => this.validateEmail(event.target.value)}
                value={email}
                helperText={validEmail ? null : 'Valid email address required'}
              />
              <TextField
                required
                id='message'
                name='message'
                label='Message'
                rows='3'
                multiline
                fullWidth
                onChange={event => this.handleMessageChange(event.target.value)}
              />
              <div style={{ height: 20 }} />
              <Terms />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.consented}
                    onChange={event => this.handleConsent(event)}
                    value={consented.toString()}
                    color='primary'
                  />
                }
                label='I confirm that I have read and agree to Camp Sasamat terms'
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.props.onClose}
                variant='outlined'
                size='large'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                onClick={this.handleSubmit}
                color='primary'
                value='Submit'
                disabled={!formFilled}
                size='large'
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        <SnackBar
          open={confirmationSnackbarOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          message={'Message sent!'}
          autoHideDuration={6000}
          onClose={() => this.setState({ confirmationSnackbarOpen: false })}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={() => this.setState({ confirmationSnackbarOpen: false })}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default withMobileDialog({ breakpoint: 'xs' })(Contact);
