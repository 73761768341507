import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';

import Home from '../Home';
import Info from '../Info';
//import Jobs from '../Jobs';

const AppWrapper = styled.div`
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export default function App() {
  return (
    <AppWrapper id='appwrapper'>
      <CssBaseline />
      <Helmet titleTemplate='%s - Camp Sasamat' defaultTitle='Camp Sasamat'>
        <meta name='description' content='Camp Sasamat website' />
      </Helmet>

      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/info' component={Info} />
        {/*<Route path='/jobs' component={Jobs} />*/}
        <Route
          path='/register'
          component={() =>
            (window.location =
              'https://www.cognitoforms.com/CampSasamat/CampSasamat2020Registration')
          }
        />
      </Switch>
      <Global
        styles={css`
          html,
          body,
          #root {
            height: 100%;
            width: 100%;
          }
          .rbc-header {
            display: none;
          }
          .rbc-agenda-date-cell,
          .rbc-agenda-time-cell,
          .rbc-agenda-event-cell {
            font-family: 'Lato' !important;
            padding: 10px;
          }
        `}
      />
    </AppWrapper>
  );
}
/*
 */
