import React from 'react';
import BigCalendar from 'react-big-calendar';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { PageDiv, SectionHeader } from '../../components/Div';
import { ShowListGrid, ShowList } from '../../components/ItemDisplay';
import * as Constants from './Constants';

const localizer = BigCalendar.momentLocalizer(moment);

let AgendaHeader = ({ label }) => (
  <div style={{ padding: '20px' }}>
    <Typography>{label}</Typography>
  </div>
);

const Content = props => (
  <PageDiv>
    <Grid container spacing={24}>
      <SectionHeader title='School of Witchcraft and Wizardry' />
      <Grid item xs={12} style={{ paddingTop: '20px' }}>
        <Grid container justify='center'>
          <Grid item />
          <Grid item>
            <img
              alt='School of Witchcraft and Wizardy Magic book'
              // sizes='(max-width: 560px) 100vw, 560px'
              src='./images/Info/magicHeader.jpg'
              width='560'
              height='242'
            />
          </Grid>
          <Grid item>
            <Typography
              style={{ maxWidth: '575px', paddingTop: '20px' }}
              gutterBottom
            >
              Sometime in the not-so-distant past there was founded a great
              school, Kromahaven, a place for young witches and wizards to learn
              and practice spellcasting, potions and defense against Dark Arts.
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ maxWidth: '575px' }}>
              After hearing of this school and its rising influence, Estaroza
              Quisitor, lord of the lake, speaker of almost-forbidden words, and
              pirate of the pantry decided to use his dark powers to put an end
              to this school.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <SectionHeader title='Choir Workshop - Kathryn Nicholson' />
      <Grid item xs={12} style={{ paddingTop: '20px' }}>
        <Grid container justify='center'>
          <Grid item>
            <Typography
              style={{ maxWidth: '575px', paddingTop: '20px' }}
              gutterBottom
            >
              Kathryn Nicholson is an accomplished choir director, well-known in
              the local choral scene. She studied voice for many years at the
              Royal Conservatory of Toronto and has sung with the Canadian Opera
              Company, Toronto Mendelssohn Choir, National Arts Centre
              Choir-in-Residence and Vancouver’s EnChor. She has Masters Degree
              in Music Therapy and for several years was on faculty at Capilano
              University as a primary vocal instructor.
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ maxWidth: '575px' }}>
              Kathryn was the Music Director at the North Shore Unitarian Church
              for 14 years before she retired to pursue other career interests.
              She currently performs in various venues around the city, sings
              with the premier women’s show choir, Pandora’s Vox and will be
              conducting 600+ voices in Vancouver’s “The Big Sing” in April
              2019.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <SectionHeader title='Speaker - Ryan Gunther' marginTop='20px' />
      <Grid item xs={12} style={{ paddingTop: '20px' }}>
        <Grid container justify='center'>
          <Grid item>
            <img
              alt='Guess who I am'
              sizes='(max-width: 560px) 100vw, 560px'
              srcSet='
              ./images/Info/DudeChilling_itczsn_c_scale,w_200.png,
              ./images/Info/DudeChilling_itczsn_c_scale,w_324.png,
              ./images/Info/DudeChilling_itczsn_c_scale,w_422.png,
              ./images/Info/DudeChilling_itczsn_c_scale,w_500.png 560w'
              src='./images/Info/DudeChilling_itczsn_c_scale,w_500.png'
            />
          </Grid>
          <Grid item>
            <Typography
              style={{ maxWidth: '575px', paddingTop: '20px' }}
              gutterBottom
            >
              Ryan is an award-winning comedian who has appeared in the Just For
              Laughs Northwest Comedy Festival, Unibrow Arts Festival and Comedy
              Waste Festival. He has toured his five star one-man show, Leash
              Your Potential, to fringe festivals across North America and was
              runner-up for Best Comedy at the Victoria Fringe. He has opened
              for Kevin Banner, who opened for Bill Burr, who was in Black Or
              White with Kevin Costner, who was in JFK with Kevin Bacon.
              &nbsp;&nbsp;
              <a href='http://ryangunther.com/'>http://ryangunther.com/</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <SectionHeader title='Schedule' />
      <Grid item xs={12} style={{ paddingTop: '20px' }}>
        <Grid container justify='center'>
          <Grid item>
            <BigCalendar
              localizer={localizer}
              events={Constants.items[2].Items}
              startAccessor='start'
              endAccessor='end'
              date='2020-06-2'
              defaultView={BigCalendar.Views.AGENDA}
              toolbar={false}
              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: '#673ab7',
                  color: 'white',
                  borderRadius: '1px',
                  border: 'none',
                  fontFamily: 'Lato'
                };

                return {
                  className: '',
                  style: newStyle
                };
              }}
              components={{
                agenda: {
                  header: AgendaHeader
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <SectionHeader title='Activities' />
      {ShowListGrid(Constants.items[0].Items)}
    </Grid>
    <Grid container spacing={24}>
      <SectionHeader title='Amenities' />
      {ShowListGrid(Constants.items[1].Items)}
    </Grid>
    <Grid container spacing={24}>
      <SectionHeader title='Packing' />
      <Grid item xs={12}>
        {Constants.items[3].Items.map((subItem, index) => (
          <>
            <Grid container justify='center'>
              <Grid item>
                <Typography variant='h6' style={{ maxWidth: '575px' }}>
                  {subItem.SubTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid item>
                <div style={{ maxWidth: '575px' }}>
                  <ul>{ShowList(subItem.Items)}</ul>
                </div>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <SectionHeader title='Location' />
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Grid item>
            <Typography
              variant='h6'
              style={{ maxWidth: '575px', paddingTop: '20px' }}
            >
              3302 Senkler Road, Belcarra, BC V3H 4S3
            </Typography>
            <Typography style={{ maxWidth: '575px' }} gutterBottom>
              Sasamat Outdoor Centre is set amongst the trees of beautiful
              Sasamat Lake, only 15 minutes from Port Moody. Drive NW on Ioco
              Road, following the signs to Belcarra. Turn right onto 1st Ave and
              continue as it turns into Bedwell Bay Road. Drive past the turnoff
              to White Pine Beach. Turn right at the stop sign, towards the
              Village of Belcarra. Turn right when you see the sign for Sasamat
              Outdoor Centre, at the bus stop at Senkler Road. Follow the dirt
              road to the camp.
            </Typography>
          </Grid>
          <Grid item>
            <img
              sizes='(max-width: 757px) 100vw, 757px'
              srcSet='
              ./images/map/map_tw2vtq_c_scale,w_200.png 200w,
              ./images/map/map_tw2vtq_c_scale,w_381.png 381w,
              ./images/map/map_tw2vtq_c_scale,w_522.png 522w,
              ./images/map/map_tw2vtq_c_scale,w_757.png 757w'
              src='./images/map/map_tw2vtq_c_scale,w_757.png'
              alt=''
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </PageDiv>
);

export default Content;
