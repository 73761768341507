import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Obfuscate from 'react-obfuscate';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  }
});

class Terms extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className='wrapper'>
        <Button variant='contained' onClick={this.handleClick}>
          TERMS
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.typography}>
            TERMS
            <hr />
            Camp Sasamat collects your email to
            <br />
            contact you about camp-related matters only.
            <br />
            <br />
            You will not be sent marketing emails and <br />
            the data will not be shared with any other parties.
            <br />
            <br />
            <hr />
            Beacon Unitarian | 318 Keary St.
            <br />
            New Westminster, BC V3L 3L3
            <br />
            <Obfuscate email='mail@campsasamat.com' /> |{' '}
            <Obfuscate tel='778-397-1442' />
          </Typography>
        </Popover>
      </div>
    );
  }
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Terms);
